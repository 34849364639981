import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import GlobalStyles from "../styles/GlobalStyles";
import feenaDrink from "../images/feena-drink.png";
import feenaDevil from "../images/feena-devil.png";
import feenaGirl from "../images/feena-girl.png";
import feenaParty from "../images/feena-party.png";
import feenaFlowers from "../images/feena-flowers.png";
//import feenaSea from "../images/feena-sea.png";
import feenaTeen from "../images/teen-feep.png";
import icon from "../images/icon.png";

const testimonials = [
  {
    description: "I asked her friends what they thought about her...",
    hype: true,
  },
  {
    description:
      "Feena is one of my oldest and dearest friends. Her positivity, creativity, and humor have been a source of untold happiness and inspiration for me during our +15 year friendship (God we're old). She is responsible for some of the best memories and biggest laughs I've ever had. Truthfully, she's more family than friend 🙂.",
    name: "Ross",
  },
  {
    description:
      "Feena is one a million. I am very lucky to have such a thoughtful, kind, caring friend like her. Don't ever change buddy ❤️",
    name: "Dave O'L",
  },
  {
    description: "Feena. Pure sound. X",
    name: "Simon",
  },
  {
    description: "Feena is great because she's always so good to her friends.",
    name: "Jack Eng",
  },
  {
    description:
      "What makes Feena awesome is that she's always been super supportive, incredibly encouraging, really positive and her enthusiasum is infectious, and it is so unfair that one person gets to have all of those traits 😂",
    name: "James W",
  },
  {
    description:
      "Feena is a cheerleader to all her friends and family, considerate, caring and creative, with a dash of mischief thrown in!",
    name: "Clodagh",
    image: feenaTeen,
  },
  {
    description:
      "Feena is the most beautiful human inside and out. She is kind, gorgeous, gives amazing advice, is insanely creative and cares deeply for those she loves.",
    name: "Bella",
  },
  {
    description:
      "Feena is kind, fun, strong and an all round gorgeous person. She is hilarious, a great friend and deserves so much happiness!",
    name: "Niamh",
  },
  {
    description:
      "Feena it’s been so great getting to know you more since you’ve move closer to us!! I really love you in our friend group you add so much and it’s a delight to have you around always!!!",
    name: "Fiona",
  },
  {
    description:
      "Feena is the kind of person that lights up an entire room by her warmth, friendly energy, and beauty! I'm so grateful to know and love her. She's also a baddie techie creative boss",
    name: "Sarah H",
  },
  {
    description:
      "The best thing about getting older is that life keeps providing. It’s been a joy getting to know you more Feena. You are uplifting, kind, inspiring and fun.",
    name: "Lindsay",
  },
  {
    description: "Feena’s kind, gas, creative & gorge girly",
    name: "Rebecca",
  },
  {
    description:
      "Feena is the most beautiful girlo on the inside and out. So caring, so funny and such a light to be around. She truly is one of a kind. I'm so lucky to call her my besto forever ❤️",
    name: "Niamh M",
  },
  {
    description:
      "Feena is a truly warm and wonderful person, a great friend and of course an extremely talented designer to boot. Swapping life updates with her over lunch is one of my favorite routines when I get back home and I’m so grateful to be her friend (now somehow with more time outside of college than in it – wild).",
    name: "Jack C",
  },
  {
    description:
      "Feena is such a genuine, nice and caring person who I am lucky to call my friend. Go on girl xx",
    name: "Ciara M",
  },
  {
    description:
      "Feena is disarmingly sound and annoyingly good at design. I for one look forward to her reign of terror as she mobiles sea life to rise against us soft land dwellers.",
    name: "Eoghan",
  },
  {
    description:
      "Feena is the most stylish person that I know. She's also incredibly considerate and kind, and one of the people who really notices when somebody is not fully included in a conversation. She is hugely empathatic and I'm very priviledged to get to call her a friend.",
    name: "Ruth",
  },
  {
    description:
      "Feena is wonderful because of how friendly, supportive and fun she is.",
    name: "Sean",
  },
  {
    description:
      "Feena is really missed for her big smile, enthusiam and amazing creative skills which really inspired us all here at workday. She has the rare natural gift of an amazing eye, and is so friendly, welcoming, and enthusiastic. Everytime I see Feena, she's always smiling and radiating great energy to everyone around her.",
    name: "Owen",
  },
  {
    description:
      "Feena is a special human because she is both kind and cool, and a great listener and friend. She always brings a smile to the room.",
    name: "Dave G",
  },
  {
    description:
      "Feena is a wonderful human who has been a great friend to me over the last few years. She's someone you want around when you're not feeling great, and even better to have around when you are! I know she's going to make waves.",
    name: "Karen",
  },
  {
    description:
      "Feena, you always bring such a beautiful energy with you everywhere you go. Always be you. Much love.",
    name: "Paul",
    image: feenaParty,
  },
  {
    description:
      "Feena is the sweetest, most caring and supportive friend, and great fun to be around too. x",
    name: "Roisin",
  },
  {
    description:
      "I’ll never forget our Designworks days bopping along to gangster rap. I’ll never let go Jack. Love you xx",
    name: "Jacqui",
  },
  {
    description:
      "Feena is great because she successfully stole Cormac away from me.",
    name: "Cathal",
  },
  {
    description:
      "Feena is amazing because she is always so bubbly, warm and makes Cormac so happy! She makes for a great flatmate in a pandemic too!",
    name: "Boom",
  },
  {
    description:
      "Feena is amazing because of happy she makes everyone around her.",
    name: "Spoon",
  },
  {
    description: "We also asked her family...",
    hype: true,
    image: feenaGirl,
  },
  {
    description:
      "Feena, you are an absolute inspiration and I love you very much.",
    name: "Cian",
  },
  {
    description:
      "Feena is a wonderful person. She has always been there for me, no matter what.",
    name: "Brion",
  },
  {
    description:
      "Feena is an amazing sister. She's loyal and compassionate. She always has your back..",
    name: "Aoishe",
  },
  {
    description:
      "Feena is a fantastic future sister in law. So supportive and considerate, and willing go the extra mile.",
    name: "Kieran",
  },
  {
    description: "...Then I asked my family",
    hype: true,
  },
  {
    description:
      "Feena. Fia. Fawn. What a beautiful woman and person you are. No wonder Cormac has fallen in love with you. You are so amazing and special. You have enriched our lives.",
    name: "Lucia & Paul",
  },
  {
    description:
      "Feena is an amazing woman, she's talented, smart, attentive and funny. Its incredible that she's able to put up with Cormac.",
    name: "Ciaran",
  },
  {
    description:
      "Feena’s beauty both externally and internally is disarming. She is one of those rare magical creatures that brings both craic and comfort into a room. When you leave her company you are already looking forward to hanging out with her again.",
    name: "Kelly",
  },
  {
    description:
      "Feena always puts those she cares for above her self, time and time again. Her commitment to friendship and kinship has to be some sort of world record and those that she holds close should consider themselves unbelievably lucky.",
    name: "Eoin",
  },
  {
    description:
      "I like when Aunty Feena plays with me. She is kind to me! I love her so much.",
    name: "Flynn",
  },
  {
    description: "Uh oh, I guess its my turn...",
    hype: true,
  },
  {
    description: "Feena, AKA Feep...",
    hype: true,
  },
  {
    description: "You're my partner,",
    hype: true,
  },
  {
    description: "My pal,",
    hype: true,
  },
  {
    description: "The love of my life...",
    hype: true,
  },
  {
    description: "And hopefully someday  Wi - Fee",
    hype: true,
  },
  {
    description: "so without further ado...",
    hype: true,
  },
  {
    description: "Feena O'Sullivan...",
    hype: true,
  },
];

const Page = styled.main`
  max-width: 600px;
  width: 100%;
  padding: 15px;
  margin: 0 auto;
`;

const Testimonials = styled.ul`
  margin-top: 100vh;
  margin-bottom: 96px;
  padding-left: 0;
  list-style: none;
`;

const Testimonial = styled.li`
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 32px;
  max-width: 560px;
  margin-bottom: 30;
  font-family: "Indie Flower", cursive;
  position: relative;

  font-family: transition: opacity 0.3s;
  
  @media(min-width: 992px) {
    min-height: 65vh;
  }

  @media(min-width: 1200px) {
    min-height: 75vh;
  }
`;

const Description = styled.p`
  font-size: 32px;
  word-spacing: 3px;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 1.45;
  ${(p) =>
    p.hype &&
    css`
      font-weight: 700;
      letter-spacing: 2px;
      word-spacing: 5px;
      text-align: center;
    `}
`;

const MarryMe = styled.div`
  z-index: 999px;
  width: 100%;
  padding: 16px 16px;
  color: #1a1a1a;
  background: #e8e8e8;
  border-radius: 8px;
  margin-top: -43px;
  margin-bottom: 150px;
  box-shadow: 0 0 15px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 440px) {
    margin-top: -36px;
  }
`;

const FeenaDevil = styled.img`
  z-index: 1000;
  max-width: 80%;
  margin: 0 auto;

  display: none;
  ${(props) => props.isDevil && `display: block;`}
`;

const FeenaDrink = styled.img`
  z-index: 1000;
  max-width: 80%;
  display: block;
  margin: 0 auto;
  ${(props) => props.isDevil && `display: none;`}
`;

const ButtonStyles = css`
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
  font-family: "Didact Gothic", sans-serif;
  font-size: 1.25rem;
  text-transform: uppercase;
  width: 160px;
  height: 54px;
  width: calc(50% - 16px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

const PageHeading = styled.div`
  font-size: 60px;
  font-family: "Gloria Hallelujah", cursive;
  font-family: "Indie Flower", cursive;
  letter-spacing: 4px;
  word-spacing: 6px;
  max-width: 100%;

  h1 {
    font-size: 48px;
    text-align: center;
    padding: 0px 15px;
  }
`;

const FeenaFlowers = styled.img`
  max-width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 100px;
  left: 0;

  @media (max-width: 991px) {
    min-height: initial;
    max-width: 100%;
    position: absolute;
    top: 140px;
  }

  @media (max-width: 412px) {
    top: 160px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const YesButton = styled.a`
  ${ButtonStyles}
  background: #08605F;
  color: white;
`;

const NoButton = styled.button`
  ${ButtonStyles}
  background: #92140C;
  color: white;
  width: 50%;
`;

const Proposal = styled.h2`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
`;

const SectionMarryMe = () => {
  const [isDevil, setIsDevil] = useState(false);

  return (
    <>
      <FeenaDevil src={feenaDevil} isDevil={isDevil} />
      <FeenaDrink src={feenaDrink} isDevil={isDevil} />
      <MarryMe>
        <Proposal>Will you marry me?</Proposal>
        <ButtonWrapper>
          <YesButton href="https://www.feenachisholm.com" target="_blank">
            Yes
          </YesButton>
          {!isDevil && <NoButton onClick={() => setIsDevil(true)}>no</NoButton>}
        </ButtonWrapper>
      </MarryMe>
    </>
  );
};

const Img = styled.img`
  max-width: 80%;
  position: fixed;
  top: 100vh;
  right: 0;
  transform: translateY(-100%);
  outline: 1px solid red;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.show ? "1" : "0")};
`;

const AltImg = styled(Img)`
  outline: 1px solid yellow;
  right: initial;
  left: 0;
`;

const TestimonialName = styled.p`
  align-self: flex-end;
  margin: 0 15px 0 0;
`;

const TestimonialImage = styled.img`
  max-width: 100%;
  margin: 0 auto;
`;

const AnimateWrapper = ({ type, children }) => (
  <div
    data-sal="fade"
    data-sal-duration="300"
    data-sal-easing="ease"
    style={{ display: "flex", flexDirection: "column" }}
  >
    {children}
  </div>
);

const IndexPage = () => {
  return (
    <>
      <PageHeading>
        <FeenaFlowers src={feenaFlowers} />
        <h1>Feena O'Sullivan</h1>
      </PageHeading>
      <Page>
        <GlobalStyles />
        <title>Home Page</title>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <AnimateWrapper>
                {testimonial.image && (
                  <TestimonialImage src={testimonial.image} />
                )}
                <Description hype={testimonial.hype}>
                  {testimonial.description}
                </Description>
                {testimonial.name && (
                  <TestimonialName>- {testimonial.name} </TestimonialName>
                )}
              </AnimateWrapper>
            </Testimonial>
          ))}
        </Testimonials>
        <SectionMarryMe />
      </Page>
    </>
  );
};

export default IndexPage;
