import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    background: #214fc6;
    color: #ffffff;
    font-family: "Didact Gothic", "Avenir",  sans-serif;
  }

  body {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    cursor: default;
    position: relative;
  }

  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;
